// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-user-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .form-group {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  button {
    background-color: #ff4444;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  button:hover {
    background-color: #cc0000;
  }
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 4px;
  }
  .success {
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
  }
  .error {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
  }`, "",{"version":3,"sources":["webpack://./src/DeleteUserForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;EAC1C;EACA;IACE,mBAAmB;EACrB;EACA;IACE,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;EACpB;EACA;IACE,yBAAyB;IACzB,cAAc;IACd,yBAAyB;EAC3B;EACA;IACE,yBAAyB;IACzB,cAAc;IACd,yBAAyB;EAC3B","sourcesContent":[".delete-user-form {\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  }\r\n  .form-group {\r\n    margin-bottom: 15px;\r\n  }\r\n  label {\r\n    display: block;\r\n    margin-bottom: 5px;\r\n    font-weight: bold;\r\n  }\r\n  input {\r\n    width: 100%;\r\n    padding: 8px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 4px;\r\n    box-sizing: border-box;\r\n  }\r\n  button {\r\n    background-color: #ff4444;\r\n    color: white;\r\n    padding: 10px 15px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    font-size: 16px;\r\n  }\r\n  button:hover {\r\n    background-color: #cc0000;\r\n  }\r\n  button:disabled {\r\n    background-color: #cccccc;\r\n    cursor: not-allowed;\r\n  }\r\n  .message {\r\n    margin-top: 15px;\r\n    padding: 10px;\r\n    border-radius: 4px;\r\n  }\r\n  .success {\r\n    background-color: #dff0d8;\r\n    color: #3c763d;\r\n    border: 1px solid #d6e9c6;\r\n  }\r\n  .error {\r\n    background-color: #f2dede;\r\n    color: #a94442;\r\n    border: 1px solid #ebccd1;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
