import React from 'react';
import { Helmet } from 'react-helmet';
import './Agreements.css';

const AgreementsAndroidRuPage = () => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
      <title>Политика конфиденциальности - Monaco Home AI</title>
      <meta name="description" content="Политика конфиденциальности приложения Monaco Home AI" />
      </Helmet>
      
      <header className="policy-header">
      <h1>Политика конфиденциальности приложения Monaco Home AI</h1>
      </header>

      <main className="policy-content">
        <section className="policy-section">
          <h2>1. Введение</h2>
          <p>
            Приложение Monaco Home AI представляет собой инструмент, разработанный для помощи пользователям в работе с планами и чертежами объектов недвижимости, позволяя загружать, хранить и взаимодействовать с данной информацией. Настоящая Политика конфиденциальности описывает, каким образом приложение собирает, использует и защищает персональные данные пользователей. Целью данного документа является информирование пользователей о практике обработки их персональной информации в соответствии с требованиями Google Play, а также законодательства Европейского союза (включая, но не ограничиваясь Общим регламентом по защите данных - GDPR), Соединенных Штатов Америки и Объединенных Арабских Эмиратов. Соблюдение данных правовых норм имеет первостепенное значение для обеспечения прозрачности обработки данных и защиты прав пользователей в различных юрисдикциях.
          </p>
        </section>

        <section className="policy-section">
        <h2>2. Сбор и обработка персональных данных</h2>
          <p>
            При регистрации в приложении Monaco Home AI пользователь предоставляет определенную информацию, которая впоследствии может обрабатываться и храниться. К таким сведениям относится:
          </p>
          <ul>
            <li>Имя и/или псевдоним, указанный пользователем при регистрации</li>
            <li>Адрес электронной почты</li>
          </ul>
          <p>
            Эти данные необходимы для создания и управления учетной записью пользователя в приложении. Адрес электронной почты, помимо прочего, может быть использован для связи с пользователем, включая предоставление технической поддержки и отправку уведомлений, связанных с работой приложения. Следует отметить, что использование адреса электронной почты для рассылки информационных, рекламных и промоционных материалов допускается только при условии получения предварительного согласия пользователя.
          </p>
          <p>
            В процессе работы с приложением пользователи могут самостоятельно загружать планы и чертежи объектов недвижимости с указанием физических размеров. Данная информация является основной функциональной составляющей приложения и хранится для обеспечения возможности дальнейшей работы пользователя с загруженными данными. Пользователь несет полную ответственность за законность и правомерность загружаемых им планов и чертежей, а также за то, что данная информация не нарушает прав и законных интересов третьих лиц и не представляет собой охраняемую законом тайну.
          </p>
          <p>
            Пароли приложения, устанавливаемые пользователями для защиты своих учетных записей, хранятся в зашифрованном виде. Такой подход обеспечивает высокий уровень безопасности и предотвращает несанкционированный доступ к учетным записям. Владелец приложения не имеет доступа к паролям пользователей в открытом виде.
          </p>
          <p>
            Факт регистрации в приложении и предоставления данных для регистрации подразумевает согласие пользователя с условиями настоящей Политики конфиденциальности.
          </p>
          <p>
            Следует обратить внимание, что платформа Google Play может самостоятельно собирать определенную информацию о пользователях приложения. Однако такая информация находится вне зоны ответственности владельца приложения и регулируется политикой конфиденциальности Google. Владелец приложения рекомендует пользователям ознакомиться с политикой конфиденциальности Google для получения более подробной информации о сборе и обработке данных со стороны платформы.
          </p>
        </section>

        <section className="policy-section">
        <h2>3. Цели обработки персональных данных</h2>
          <p>
            Сбор и обработка персональных данных пользователей приложения Monaco Home AI осуществляются в следующих целях:
          </p>
          <ul>
            <li><strong>Обеспечение основной функциональности приложения:</strong> Предоставление пользователям возможности загружать, хранить и работать с планами и чертежами недвижимости.</li>
            <li><strong>Управление учетными записями пользователей:</strong> Создание, идентификация и поддержка учетных записей пользователей.</li>
            <li><strong>Связь с пользователями:</strong> Предоставление технической поддержки, отправка уведомлений о работе приложения и важных обновлениях.</li>
            <li><strong>Улучшение приложения и пользовательского опыта:</strong> Анализ использования приложения для выявления проблем, улучшения производительности и разработки новых функций.</li>
            <li><strong>Предотвращение мошенничества и злоупотреблений:</strong> Выявление и предотвращение действий, нарушающих условия использования приложения или законодательство.</li>
            <li><strong>Соблюдение требований законодательства:</strong> Выполнение юридических обязательств, связанных с защитой персональных данных пользователей.</li>
          </ul>
          <p>
            Все цели обработки персональных данных соответствуют принципам законности, справедливости и прозрачности, установленным GDPR и PDPL ОАЭ.
          </p>
        </section>

        <section className="policy-section">
        <h2>4. Права пользователей</h2>
          <p>
            В соответствии с GDPR пользователи приложения Monaco Home AI обладают следующими правами:
          </p>
          <ul>
            <li><strong>Право на информацию:</strong> Пользователи имеют право знать, какие персональные данные о них собираются, цели обработки, сроки хранения и другие сведения, касающиеся обработки их данных.</li>
            <li><strong>Право на доступ:</strong> Пользователи могут запросить доступ к своим персональным данным, хранящимся в приложении, и получить их копию.</li>
            <li><strong>Право на исправление:</strong> Пользователи вправе требовать исправления неточных или неполных персональных данных.</li>
            <li><strong>Право на удаление ("право быть забытым"):</strong> При определенных условиях пользователи могут потребовать удаления своих персональных данных.</li>
            <li><strong>Право на ограничение обработки:</strong> Пользователи могут потребовать приостановления обработки их персональных данных в определенных ситуациях.</li>
            <li><strong>Право на переносимость данных:</strong> Пользователи имеют право получить свои персональные данные в структурированном, общепринятом и машиночитаемом формате и передать их другому контроллеру.</li>
            <li><strong>Право на возражение:</strong> Пользователи могут возразить против обработки их персональных данных в определенных случаях.</li>
            <li><strong>Права в отношении автоматизированного принятия решений и профилирования:</strong> Пользователи имеют право не быть объектом решения, основанного исключительно на автоматизированной обработке, включая профилирование, если такое решение влечет за собой юридические последствия или иным образом существенно затрагивает их.</li>
          </ul>
          <p>
            Законодательство США также предоставляет пользователям определенные права в отношении их персональных данных. В соответствии с CCPA/CPRA пользователи из Калифорнии имеют право знать о собираемых данных, требовать их удаления и отказываться от их продажи или передачи. CPRA также предоставляет право на исправление неточных данных и ограничение использования чувствительной персональной информации.
          </p>
          <p>
            Законодательство ОАЭ (PDPL) предоставляет пользователям следующие права: право на доступ, право на исправление, право на ограничение обработки, право на прекращение обработки, право на переносимость данных, право на возражение против автоматизированного принятия решений и право на отказ от прямого маркетинга.
          </p>
          <p>
            Для реализации своих прав пользователи приложения Monaco Home AI могут обратиться в службу поддержки приложения.
          </p>
        </section>

        <section className="policy-section">
          <h2>5. Согласие пользователя</h2>
          <p>
            Согласие пользователя на обработку персональных данных является необходимым условием для использования приложения Monaco Home AI. При регистрации пользователь дает явное согласие на обработку своих персональных данных в соответствии с условиями настоящей Политики конфиденциальности. В процессе регистрации пользователю предоставляется возможность ознакомиться с полным текстом Политики конфиденциальности до того, как он завершит процесс создания учетной записи. Для обеспечения соответствия требованиям GDPR может быть реализован механизм дополнительного подтверждения согласия, например, через отдельный чекбокс.
          </p>
        </section>

        <section className="policy-section">
          <h2>6. Передача персональных данных третьим лицам</h2>
          <p>
            В процессе работы приложения Monaco Home AI может возникнуть необходимость в передаче персональных данных пользователей третьим лицам. К таким случаям может относиться использование сторонних сервисов для аналитики, хранения данных или обеспечения функциональности приложения. В случае передачи данных третьим лицам, владелец приложения обязуется обеспечить, чтобы такие третьи лица соблюдали требования к защите данных, аналогичные изложенным в настоящей Политике конфиденциальности. Передача данных осуществляется только в тех случаях, когда это необходимо для достижения целей, указанных в разделе 3, и при условии информирования пользователя о такой передаче.
          </p>
        </section>

        <section className="policy-section">
          <h2>7. Безопасность данных</h2>
          <p>
            Владелец приложения Monaco Home AI предпринимает все необходимые меры для обеспечения безопасности персональных данных пользователей. К таким мерам относятся шифрование данных при передаче и хранении, использование безопасных серверов и регулярное обновление протоколов безопасности. Применяются меры по предотвращению несанкционированного доступа, использования, раскрытия, изменения или уничтожения персональных данных. Несмотря на принимаемые меры, следует помнить, что ни один способ передачи данных через Интернет или метод электронного хранения не является абсолютно безопасным. Владелец приложения стремится использовать коммерчески приемлемые средства защиты персональных данных, но не может гарантировать их абсолютную безопасность.
          </p>
        </section>

        <section className="policy-section">
          <h2>8. Хранение персональных данных</h2>
          <p>
            Персональные данные пользователей приложения Monaco Home AI хранятся до тех пор, пока это необходимо для предоставления услуг, обеспечения функциональности приложения и выполнения законных обязательств. Сроки хранения различных категорий данных могут отличаться. Например, данные учетной записи пользователя обычно хранятся до тех пор, пока учетная запись активна, если иное не предусмотрено законодательством. Загруженные пользователем планы и чертежи хранятся для обеспечения возможности дальнейшей работы с ними, либо, в течение 1 (одного) года с даты их последнего использования.
          </p>
        </section>

        <section className="policy-section">
          <h2>9. Изменения в Политике конфиденциальности</h2>
          <p>
            Владелец приложения Monaco Home AI оставляет за собой право вносить изменения в настоящую Политику конфиденциальности без дополнительного уведомления. В случае внесения существенных изменений, которые могут повлиять на права пользователей, владелец приложения может предпринять разумные меры для информирования пользователей о таких изменениях, например, путем размещения уведомления в приложении или отправки сообщения по электронной почте. Продолжение использования приложения после внесения изменений в Политику конфиденциальности означает согласие пользователя с новыми условиями. Если пользователь не согласен с измененными условиями использования, он должен прекратить использование приложения. Несмотря на возможность внесения изменений без предварительного уведомления, владелец приложения стремится к прозрачности и рекомендует пользователям регулярно пересматривать Политику конфиденциальности для ознакомления с последними обновлениями.
          </p>
        </section>

        <section className="policy-section">
          <h2>Заключение</h2>
          <p>
            Настоящая Политика конфиденциальности разработана с целью обеспечения соответствия требованиям законодательства Европейского союза (включая GDPR), Соединенных Штатов Америки и Объединенных Арабских Эмиратов, а также требованиям Google Play. Владелец приложения Monaco Home AI стремится к защите персональных данных пользователей и обеспечению прозрачности процессов их обработки. Пользователям рекомендуется внимательно ознакомиться с настоящей Политикой конфиденциальности и регулярно проверять ее на наличие обновлений. Соблюдение принципов защиты данных является приоритетом для владельца приложения, и принимаются все необходимые меры для обеспечения безопасности и конфиденциальности персональной информации пользователей.
          </p>
        </section>
      </main>

      <footer className="policy-footer">
        <p>Последнее обновление: {new Date("4/01/2025").toLocaleDateString('ru-RU')}</p>
      </footer>
    </div>
  );
};

export default AgreementsAndroidRuPage;