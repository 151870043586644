// import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/monaco.jpg" className="App-logo" />
        <p>
        <div className='App-description'>Apartments renovation with the most rated brand names and design trends in a fastest possible time and price.</div>
        </p>
        <p><a href="#" className='App-link'>Contact us</a></p>
      </header>
    </div>
  );
}

export default App;
