import React from 'react';
import { Helmet } from 'react-helmet';
import './Agreements.css';

const AgreementsIOSEnPage = () => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy Policy - Monaco Home AI</title>
        <meta name="description" content="Privacy Policy for Monaco Home AI application" />
      </Helmet>

      <header className="policy-header">
        <h1>Privacy Policy for Monaco Home AI Application</h1>
      </header>

      <main className="policy-content">
        <section className="policy-section">
          <h2>1. Introduction</h2>
          <p>
            The Monaco Home AI application is a tool designed to assist users in working with real estate plans and drawings, 
            enabling them to upload, store, and interact with such information. This Privacy Policy describes how the 
            application collects, uses, and protects users' personal data. The purpose of this document is to inform users 
            about the practices of handling their personal information in accordance with the requirements of the Apple App Store, 
            as well as the laws of the European Union (including, but not limited to, the General Data Protection Regulation - GDPR), 
            the United States of America, and the United Arab Emirates. Compliance with these legal norms is paramount to ensure 
            transparency in data processing and to protect the rights of users in various jurisdictions.
          </p>
        </section>

        <section className="policy-section">
          <h2>2. Collection and Processing of Personal Data</h2>
          <p>
            During registration in the Monaco Home AI application, the user provides certain information that may subsequently 
            be processed and stored. This information includes:
          </p>
          <ul>
            <li>The name and/or pseudonym provided by the user during registration</li>
            <li>The email address</li>
          </ul>
          <p>
            These data are necessary for the creation and management of the user's account in the application. The email address, 
            among other things, may be used to communicate with the user, including providing technical support and sending 
            notifications related to the operation of the application. It should be noted that the use of the email address for 
            sending informational, advertising, and promotional materials is permitted only upon obtaining the user's prior consent.
          </p>
          <p>
            During the use of the application, users can independently upload real estate plans and drawings, specifying physical 
            dimensions. This information is a core functional component of the application and is stored to enable further user 
            interaction with the uploaded data. The user bears full responsibility for the legality and legitimacy of the plans 
            and drawings they upload, as well as for ensuring that this information does not infringe on the rights and legitimate 
            interests of third parties and does not constitute a legally protected secret.
          </p>
          <p>
            Application passwords, set by users to protect their accounts, are stored in encrypted form. This approach ensures 
            a high level of security and prevents unauthorized access to user accounts. The application owner does not have access 
            to user passwords in plain text.
          </p>
          <p>
            The fact of registration in the application and providing data for registration implies the user's consent to the terms 
            of this Privacy Policy.
          </p>
          <p>
            It should be noted that the Apple App Store platform may independently collect certain information about application users. 
            However, such information is outside the control of the application owner and is governed by Apple's privacy policy. 
            The application owner recommends that users familiarize themselves with Apple's privacy policy for more detailed 
            information on the collection and processing of data by the platform.
          </p>
        </section>

        <section className="policy-section">
          <h2>3. Purposes of Personal Data Processing</h2>
          <p>
            The collection and processing of personal data of users of the Monaco Home AI application are carried out for the 
            following purposes:
          </p>
          <ul>
            <li><strong>Ensuring the main functionality of the application:</strong> Providing users with the ability to upload, store, and work with real estate plans and drawings.</li>
            <li><strong>Managing user accounts:</strong> Creating, identifying, and supporting user accounts.</li>
            <li><strong>Communicating with users:</strong> Providing technical support, sending notifications about the application's operation and important updates.</li>
            <li><strong>Improving the application and user experience:</strong> Analyzing application usage to identify problems, improve performance, and develop new features.</li>
            <li><strong>Preventing fraud and abuse:</strong> Detecting and preventing actions that violate the terms of use of the application or legislation.</li>
            <li><strong>Compliance with legal requirements:</strong> Fulfilling legal obligations related to the protection of users' personal data.</li>
          </ul>
          <p>
            All purposes of personal data processing comply with the principles of legality, fairness, and transparency established 
            by the GDPR and the UAE PDPL.
          </p>
        </section>

        <section className="policy-section">
          <h2>4. User Rights</h2>
          <p>
            In accordance with the GDPR, users of the Monaco Home AI application possess the following rights:
          </p>
          <ul>
            <li><strong>Right to Information:</strong> Users have the right to know what personal data is being collected about them, the purposes of processing, storage periods, and other information relating to the processing of their data.</li>
            <li><strong>Right of Access:</strong> Users can request access to their personal data stored in the application and obtain a copy of it.</li>
            <li><strong>Right to Rectification:</strong> Users have the right to demand the correction of inaccurate or incomplete personal data.</li>
            <li><strong>Right to Erasure ("right to be forgotten"):</strong> Under certain conditions, users can request the deletion of their personal data.</li>
            <li><strong>Right to Restriction of Processing:</strong> Users can demand the suspension of the processing of their personal data in specific situations.</li>
            <li><strong>Right to Data Portability:</strong> Users have the right to receive their personal data in a structured, commonly used, and machine-readable format and to transfer it to another controller.</li>
            <li><strong>Right to Object:</strong> Users can object to the processing of their personal data in certain cases.</li>
            <li><strong>Rights in relation to automated decision-making and profiling:</strong> Users have the right not to be subject to a decision based solely on automated processing, including profiling, if such decision produces legal effects concerning them or similarly significantly affects them.</li>
          </ul>
          <p>
            U.S. law also provides users with certain rights regarding their personal data. Under the CCPA/CPRA, California users 
            have the right to know about the data collected, to request its deletion, and to opt-out of its sale or sharing. The 
            CPRA also provides the right to correct inaccurate data and to limit the use of sensitive personal information.
          </p>
          <p>
            UAE legislation (PDPL) grants users the following rights: right of access, right of rectification, right to restriction 
            of processing, right to stop processing, right to data portability, right to object to automated decision-making, and 
            right to opt-out of direct marketing.
          </p>
          <p>
            To exercise their rights, users of the Monaco Home AI application can contact the application's support service.
          </p>
        </section>

        <section className="policy-section">
          <h2>5. User Consent</h2>
          <p>
            User consent to the processing of personal data is a necessary condition for using the Monaco Home AI application. Upon 
            registration, the user gives explicit consent to the processing of their personal data in accordance with the terms of 
            this Privacy Policy. During the registration process, the user is given the opportunity to familiarize themselves with 
            the full text of the Privacy Policy before completing the account creation process. To ensure compliance with GDPR 
            requirements, a mechanism for additional confirmation of consent may be implemented, such as through a separate checkbox.
          </p>
        </section>

        <section className="policy-section">
          <h2>6. Transfer of Personal Data to Third Parties</h2>
          <p>
            During the operation of the Monaco Home AI application, it may be necessary to transfer users' personal data to third 
            parties. Such cases may include the use of third-party services for analytics, data storage, or ensuring the 
            functionality of the application. If data is transferred to third parties, the application owner is obliged to ensure 
            that such third parties comply with data protection requirements similar to those outlined in this Privacy Policy. 
            Data transfer is carried out only in cases where it is necessary to achieve the purposes specified in section 3, 
            and provided that the user is informed about such transfer.
          </p>
        </section>

        <section className="policy-section">
          <h2>7. Data Security</h2>
          <p>
            The owner of the Monaco Home AI application takes all necessary measures to ensure the security of users' personal data. 
            These measures include encrypting data during transmission and storage, using secure servers, and regularly updating 
            security protocols. Measures are taken to prevent unauthorized access, use, disclosure, alteration, or destruction of 
            personal data. Despite the measures taken, it should be remembered that no method of transmitting data over the Internet 
            or method of electronic storage is completely secure. The application owner strives to use commercially reasonable means 
            to protect personal data but cannot guarantee its absolute security.
          </p>
        </section>

        <section className="policy-section">
          <h2>8. Storage of Personal Data</h2>
          <p>
            The personal data of users of the Monaco Home AI application is stored as long as it is necessary for the provision of 
            services, ensuring the functionality of the application, and fulfilling legal obligations. The storage periods for 
            different categories of data may vary. For example, user account data is typically stored as long as the account is 
            active, unless otherwise provided by law. Uploaded plans and drawings are stored to enable further work with them or 
            for one (1) year from the date of their last use.
          </p>
        </section>

        <section className="policy-section">
          <h2>9. Changes to the Privacy Policy</h2>
          <p>
            The owner of the Monaco Home AI application reserves the right to make changes to this Privacy Policy without further 
            notice. In the event of significant changes that may affect users' rights, the application owner may take reasonable 
            steps to inform users about such changes, for example, by posting a notification in the application or sending an 
            email. Continued use of the application after changes to the Privacy Policy constitutes the user's consent to the new 
            terms. If a user does not agree with the amended terms of use, they must stop using the application. Despite the 
            possibility of making changes without prior notice, the application owner strives for transparency and encourages users 
            to periodically review the Privacy Policy to familiarize themselves with the latest updates.
          </p>
        </section>

        <section className="policy-section">
          <h2>Conclusion</h2>
          <p>
            This Privacy Policy has been developed to ensure compliance with the requirements of the legislation of the European 
            Union (including GDPR), the United States of America, and the United Arab Emirates, as well as the requirements of 
            the Apple App Store. The owner of the Monaco Home AI application strives to protect the personal data of users and ensure 
            the transparency of its processing. Users are advised to carefully read this Privacy Policy and regularly check it for 
            updates. Adherence to data protection principles is a priority for the application owner, and all necessary measures 
            are taken to ensure the security and confidentiality of users' personal information.
          </p>
        </section>
      </main>

      <footer className="policy-footer">
        <p>Last updated: {new Date("04/01/2025").toLocaleDateString()}</p>
      </footer>
    </div>
  );
};

export default AgreementsIOSEnPage;